import * as Sentry from '@sentry/browser';

angular.module('naex.user')

    .factory('authService', function($rootScope, $state, $q, $http, $env, $cookieStore, User, entityConst, translationService) {

        function getAllRoles(role)
        {
            var allRoles = [role];
            var inheritedRoles = authService.roleHierarchy[role];
            angular.forEach(inheritedRoles, function(inheritedRole){
                allRoles = _.union(allRoles, getAllRoles(inheritedRole));
            });
            return allRoles;
        }

        var lastNextState = null;

        var authService = {
            loginDetails: null,
            roleHierarchy: {},
            login: function() {
                var defer = $q.defer(),
                    headers = {};
                if (authService.loginDetails) {
                    headers = {
                        PHP_AUTH_USER: authService.loginDetails.username,
                        PHP_AUTH_PW: !/[^\u0000-\u00ff]/g.test(authService.loginDetails.password) ? authService.loginDetails.password : '',
                        PHP_SECURITY_CODE: !/[^\u0000-\u00ff]/g.test(authService.loginDetails.securityCode) ? authService.loginDetails.securityCode : '',
                        PHP_TRUSTED_DEVICE: authService.loginDetails.trustedDevice
                    };
                }

                User.me({ 'locale': translationService.getLocale() }, null, headers)
                    .then(function(user) {
                        if (user != null) {
                            $rootScope.$me = user;
                            $cookieStore.put('NX_USER_TYPE', user.employee.company.corsearchCompany ? 'corsearch' : 'naex');
                            if (user.employee.company.corsearchCompany) {
                                $rootScope.$me.isCorsearchUser = true;
                                if (!$rootScope.$me.sessionData || !$rootScope.$me.sessionData.corsearchLogoUrl) {
                                    $rootScope.$me.sessionData = {
                                        corsearchLogoUrl: 'https://portal.corsearch.com/'
                                    };
                                }
                            }

                            Sentry.setUser({
                                id: user.id + (user.impersonated ? ' (!)' : ''),
                                username: user.employee.getNameLocalized(),
                            });

                            defer.resolve(user);
                        } else {
                            Sentry.setUser(null);

                            $rootScope.$broadcast('event:auth-loginFailed', {message: translationService.translate('Server error. Please try again later.')});
                            defer.reject(null);
                        }
                    }, function(response) {
                        if (response.status === 401) {
                            if (response.data === 'Access denied.') {
                                $rootScope.$broadcast('event:auth-loginFailed', {message: translationService.translate('Bad credentials.')});
                            } else {
                                if (response.data.success == true) {
                                    $rootScope.$broadcast('event:auth-twoFactor');
                                } else {
                                    if (response.data.error === 'code') {
                                        $rootScope.$broadcast('event:auth-loginFailed', {message: translationService.translate('Invalid or expired security code.')});
                                    }
                                }
                            }
                        } else {
                            $rootScope.$broadcast('event:auth-loginFailed', {message: translationService.translate('Server error. Please try again later.')});
                        }

                        Sentry.setUser(null);

                        defer.reject(null);
                    })
                    .finally(function() {
                        authService.loginDetails = null;
                    });
                return defer.promise;
            },
            logout: function() {
                return $http.post($env.apiUrl + 'logout').then(function(response) {
                    if (response.data.success) {
                        Sentry.setUser(null);
                        $rootScope.$me = null;
                    }
                });
            },
            getUser: function() {
                return $rootScope.$me;
            },
            isLoggedIn: function() {
                return $rootScope.$me != null;
            },
            hasPermission: function(permissionName){
                var user = authService.getUser();
                if (!user || !entityConst.Permission) {
                    return false;
                }

                var role = entityConst.Permission[permissionName];
                var permission = _.find(authService.getUser().permissionList, function(permission) {
                    var userRoles = getAllRoles(permission.name);
                    return userRoles.indexOf(role) >= 0;
                });

                return !!permission;
            },
            hasMultiplePermissions(permissions) {
                if (permissions.indexOf('&&') > -1) {
                    var hasAllPermission = true;
                    permissions = permissions.split('&&');
                    angular.forEach(permissions, function (value) {
                        if (!authService.hasPermission(value.trim())) {
                            hasAllPermission = false;
                        }
                    });

                    return hasAllPermission;
                } else if (permissions.indexOf('||') > -1) {
                    var hasAnyPermission = false;
                    permissions = permissions.split('||');
                    angular.forEach(permissions, function (value) {
                        if (authService.hasPermission(value.trim())) {
                            hasAnyPermission = true;
                        }
                    });

                    return hasAnyPermission;
                } else {
                    return authService.hasPermission(permissions);
                }
            },

            isNcUser: function() {
                if (!entityConst.Company || !this.getUser()) {
                    return false;
                }

                return _.contains(entityConst.Company.$NC_COMPANY_IDS, this.getUser().employee.company.id);
            },
            authorize: function (event, nextState) {

                lastNextState = nextState || lastNextState;

                // if module data isn't loaded yet
                if (!entityConst['Permission'])
                    return;

                if (lastNextState.data &&
                    lastNextState.data.permissions &&
                    lastNextState.data.permissions.length > 0) {

                    var permissions = lastNextState.data.permissions;
                    var isDenied = false;

                    for (var i = 0; i < permissions.length; i++) {
                        if (!authService.hasMultiplePermissions(permissions[i])) {
                            isDenied = true;
                        }
                    }

                    if (isDenied) {
                        event.preventDefault();
                        var redirectTo = lastNextState.data.redirectState || 'company.home';
                        if (redirectTo !== lastNextState.name)
                            $state.go(redirectTo);
                    }
                }
            }
        };

        return authService;
    });
