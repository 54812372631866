import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new AngularIntegration()],
    release: SENTRY_RELEASE,
    denyUrls: [
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
    ignoreErrors: [
        '$state.transition(components',
        'updateView(components',
        'equals(components',
        'Object.toString(<anonymous>)',
        '_typeof(components',
        '_leaflet_pos',
    ]

});

angular
    .module('naex', [
        // Own modules
        'naex.user',
        'naex.project',
        'naex.product',
        'naex.company',
        'naex.invoicing',
        'naex.trademark',
        'naex.watching',
        'naex.watchingIframe',
        'naex.system',
        'naex.database',
        'naex.crossReference',

        // 3rd modules
        'ngDragDrop',
        'ngSanitize',
        'ngLocale',
        'ngCookies',
        'ngSentry',
        'ui.router.compat',
        'ui.bootstrap',
        'ui.bootstrap.dropdown',
        'ui.sortable',
        'kendo.directives',
        'pascalprecht.translate',
    ])

    .run(function ($rootScope, $state, $stateParams, $log, $env, entityConst, $window, nxConfig, faviconService, pageTitleService, translationService) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.$log = $log;
        $rootScope.$env = $env;
        $rootScope.entityConst = entityConst;
        $rootScope.iframeView = $window.top !== $window.self;
        $rootScope.nxConfig = nxConfig;
        $rootScope.$translationService = translationService;
    });
